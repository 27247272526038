:root {
  --background: #f8f8f8;
  --lightgrey: #e6e6e6;
  --grey: #d1d1d1;
  --greytext: rgb(125, 125, 125);
}

.App {
  margin: 0;
  padding: 0;
  background: var(--background);
  overflow: hidden;
}

html {
  scroll-behavior: smooth;
}

p,
h1,
h2,
h3,
h4 {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

a {
  text-decoration: none;
  color: black;
  margin: 0;
  padding: 0;
}

/* Header styles */
.app-header {
  width: 100%;
  height: 5rem;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  z-index: 100;
}

.nav-container {
  display: flex;
  flex-direction: row;
  width: 20rem;
  height: 3rem;
  background: rgba(238, 238, 238, 0.85);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem;
  border-radius: 1.5rem;
  transition: all 0.3s ease;
}

.section-indicator {
  height: 2.1rem;
  width: 80px;
  border-radius: 1.2rem;
  background: rgba(172, 172, 172, 0.2);
  transition: transform 0.3s ease-in-out;
  position: absolute;
}

/* About Section */

.about-icons {
  font-size: 2rem;
  margin-top: 0.5rem;
}

.about-icons .icon {
  margin-left: 0.5rem;
  cursor: pointer;
  color: #00b2f9;
  transition: all 0.3s ease;
}

.about-icons .icon:hover {
  color: #029edb;
}

.about-container {
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
}

.about-container .left-container {
  height: 100%;
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-left: 5%;
}

.about-container .left-container h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.about-container .left-container p,
.about-container .left-container span {
  font-size: 1.5rem;
}

.about-container .right-container {
  width: 60%;
  height: 100%;
  position: relative;
}

.card-container {
  padding: 2.2rem 2rem;
  line-height: 1.8rem;
  font-size: 1.1rem;
  height: fit-content;
  border-radius: 0.6rem;
  background: white;
  color: black;
  box-shadow: rgba(126, 127, 127, 0.1) 0px 6px 10px;
  position: absolute;
  transform: translateY(var(--scroll));
}

.card-container.one {
  min-height: 7rem;
  width: 40%;
  top: 40%;
  left: 2%;
  display: flex;
  align-items: center;
}

.card-container.two {
  min-height: 5rem;
  width: 30%;
  top: 70%;
  left: 35%;
}

.card-container.three {
  min-height: 5rem;
  width: 30%;
  top: 22%;
  left: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .card-container.three {
    top: 15%;
    left: 40%;
  }

  .card-container.three {
    text-align: center;
  }

  .card-container.two {
    top: 75%;
    left: 35%;
  }

  .card-container.one {
    top: 40%;
    left: 5%;
  }
}

@media screen and (max-width: 680px) {
  .card-container {
    line-height: 1.5rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 440px) {
  .about-container {
    flex-direction: column;
    padding: 1rem;
    padding-top: 4rem;
  }

  .about-container .left-container p {
    text-align: center;
  }

  .about-container .left-container {
    width: 80%;
    height: 35rem;
    align-items: center;
  }

  .about-container .left-container h1 {
    font-size: 2.5rem;
  }

  .about-container .right-container {
    width: 100%;
  }

  .card-container.three {
    top: -10%;
    left: 45%;
  }

  .card-container.two {
    top: 70%;
    left: 35%;
  }

  .card-container.one {
    top: 23%;
    left: 0;
    width: 50%;
  }

  .card-container {
    line-height: 1.6rem;
    font-size: 1.2rem;
  }

  .nav-container {
    width: 15rem;
    height: 3rem;
    font-size: 0.9rem;
  }

  .section-indicator {
    height: 2.1rem;
    width: 70px;
    border-radius: 1.2rem;
    background: rgba(172, 172, 172, 0.2);
    transition: transform 0.3s ease-in-out;
    position: absolute;
  }
}

@media screen and (min-width: 1800px) {
  .card-container {
    line-height: 2rem;
    font-size: 1.3rem;
  }

  .about-container .left-container {
    margin-left: 10%;
  }
}

@media screen and (max-width: 400px) {
  .card-container {
    line-height: 1.4rem;
    font-size: 1rem;
  }

  .card-container.two {
    top: 66%;
  }

  .card-container.one {
    top: 20%;
  }

  .card-container.three {
    top: -18%;
  }
}

@media screen and (max-width: 380px) {
  .card-container {
    line-height: 1.2rem;
    font-size: 0.8rem;
  }

  .card-container.three {
    top: -15%;
  }
}

/* Projects Section */
.projects-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4rem 0 2rem 0;
}

.projects-container-text {
  width: 60%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin-bottom: 3rem;
}

.projects-container-text h1 {
  font-size: 3rem;
  margin-bottom: 0.5rem;
}

.projects-container-text p {
  font-size: 1.5rem;
}

.project-description {
  font-size: 1rem;
  margin-bottom: 0.8rem;
}

.techstack {
  font-weight: bold;
  font-size: 1rem;
}

.projects-grid {
  height: fit-content;
  padding: 3rem 2.6rem;
}

.projects-grid .row {
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.small-project,
.large-project {
  border-radius: 2rem;
  box-shadow: rgba(126, 127, 127, 0.1) 0px 6px 10px;
  position: relative;
  overflow: hidden;
}

.small-project {
  height: 30rem;
  width: 38%;
}

.large-project {
  height: 30rem;
  width: 60%;
}

.first.row .small-project {
  background-image: linear-gradient(to bottom, #03c5ff, #009af9);
}

.first.row .large-project {
  background-image: linear-gradient(to bottom left, #6e00ff, #d709ff);
  position: relative;
}

.second.row .large-project {
  background-image: linear-gradient(to bottom right, #3c1aff, #4de8f8);
}

.second.row .small-project {
  background: white;
}

.third.row .small-project {
  background-image: linear-gradient(to bottom right, #00b2f9, #43fd86);
}

.third.row .large-project {
  background-image: linear-gradient(to right, #8618ff, #aa39e3);
}

.fourth.row .large-project {
  background: white;
}

.fourth.row .small-project {
  background-image: linear-gradient(to bottom, #03c5ff, #009af9);
}

.project-info {
  height: fit-content;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 3rem 3rem 0 3rem;
  color: white;
}

.project-info h1 {
  font-size: 1.4rem;
  margin-bottom: 1rem;
}

.large-project.airline .project-info {
  color: #0c5f8e;
}

.small-project.seu .project-info {
  color: #2e90c8;
}

.link-icons {
  height: fit-content;
  width: fit-content;
  display: flex;
  flex-direction: row;
  position: absolute;
  top: 2rem;
  right: 2rem;
}

.link-icons a {
  font-size: 2rem;
  padding: 0.4rem;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 50%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 0.8rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.link-icons a:hover {
  background: rgba(255, 255, 255, 0.6);
}

.small-project.seu .link-icons a,
.large-project.airline .link-icons a {
  background: var(--lightgrey);
}

.sliding-images {
  position: absolute;
  bottom: -3rem;
}

.sliding-images .row {
  display: flex;
  flex-direction: row;
  width: 120%;
  height: 10rem;
  overflow-x: hidden;
  margin-bottom: 0.5rem;
}

.sliding-images .row .image {
  height: 100%;
  width: 35%;
  border-radius: 1rem;
  box-shadow: rgba(126, 127, 127, 0.1) 0px 6px 10px;
  background: white;
  margin-right: 0.7rem;
  overflow: hidden;
}

.sliding-images .row .image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.placeholder-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.smartmeal-images {
  height: 80%;
  width: 100%;
  position: relative;
}

.airline-images {
  height: 80%;
  width: 100%;
  position: relative;
}

.dark-mode-image {
  height: 80%;
  width: 500px;
  position: relative;
  border-radius: 1rem;
  position: absolute;
  bottom: 0;
  left: 10%;
}

.first-pic {
  height: 75%;
  width: 500px;
  position: relative;
  border-radius: 1rem;
  position: absolute;
  bottom: 13%;
  left: 10%;
}

.light-mode-image {
  height: 90%;
  width: 500px;
  position: relative;
  border-radius: 1rem;
  position: absolute;
  bottom: 0;
  left: 30%;
}

.second-pic {
  height: 85%;
  width: 550px;
  position: relative;
  border-radius: 1rem;
  position: absolute;
  bottom: 12%;
  left: 40%;
}

.dark-mode-image img,
.second-pic img,
.light-mode-image img,
.first-pic img {
  height: 100%;
  width: 100%;
  object-fit: stretch;
  border-radius: 1rem;
}

.seu-website-image {
  height: 15rem;
  width: 30rem;
  position: relative;
  border-radius: 1rem;
  position: absolute;
  bottom: -1rem;
  left: -5rem;
  transform: rotate(15deg);
}

.seu-website-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.gradetracker-image,
.chore-share-image {
  height: 250px;
  width: 500px;
  border-radius: 1rem;
  position: absolute;
  bottom: -1rem;
  left: 15%;
  transform-origin: center;
}

.gradetracker-image img,
.chore-share-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
}

.data-structures-img {
  height: 50%;
  width: 68%;
  position: absolute;
  bottom: -1rem;
  right: -3rem;
  transform-origin: center;
}

.data-structures-img img {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 1rem;
  box-shadow: rgba(126, 127, 127, 0.1) 0px 6px 10px;
}

.moving-text-container {
  width: 400%;
  position: absolute;
  bottom: 0;
  left: -25%;
}

.moving-text-container h2,
.moving-text-container h1,
.moving-text-container h3 {
  color: white;
  opacity: 0.6;
  margin: 0.6rem;
}
.moving-text-container h1 {
  font-size: 2rem;
}

@media screen and (min-width: 1800px) {
  .sliding-images .row {
    width: 150%;
  }

  .seu-website-image {
    height: 18rem;
    width: 35rem;
    position: relative;
    border-radius: 1rem;
    position: absolute;
    bottom: -1rem;
    left: -5rem;
    transform: rotate(15deg);
  }
}
@media screen and (max-width: 1050px) {
  .projects-grid .row {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0;
    height: fit-content;
  }

  .sliding-images {
    position: absolute;
    bottom: -3rem;
  }

  .sliding-images .row {
    display: flex;
    flex-direction: row;
    width: 150%;
    height: 8rem;
    overflow-x: hidden;
    margin-bottom: 0.5rem;
  }

  .small-project,
  .large-project {
    height: 28rem;
    width: 85%;
    margin-bottom: 2rem;
  }

  .small-project.seu .project-info {
    width: 95%;
    align-items: end;
    padding-left: 0;
    padding-right: 3rem;
  }

  .small-project.seu .project-info .project-description {
    text-align: end;
    padding-left: 0.5rem;
  }

  .seu-website-image {
    height: 18rem;
    width: 35rem;
    position: relative;
    border-radius: 1rem;
    position: absolute;
    bottom: -1rem;
    left: -5rem;
    transform: rotate(15deg);
  }

  .small-project.seu .link-icons {
    top: 2rem;
    left: 2rem;
  }
}

@media screen and (max-width: 720px) {
  .link-icons a {
    font-size: 1.5rem;
    padding: 0.4rem;
    border-radius: 50%;
    margin-left: 0.8rem;
  }

  .small-project.seu .link-icons {
    top: 2.5rem;
  }

  .link-icons {
    top: 2.5rem;
    right: 1.5rem;
  }

  .projects-container-text h1 {
    font-size: 2.4rem;
    margin-bottom: 1rem;
  }

  .projects-container-text p {
    font-size: 1rem;
  }

  .project-description {
    font-size: 0.8rem;
    margin-bottom: 0.8rem;
  }

  .techstack {
    font-weight: bold;
    font-size: 0.8rem;
  }

  .project-info h1 {
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .large-project.vektor .project-info h1 {
    width: 60%;
  }
}

@media screen and (max-width: 440px) {
  .link-icons a {
    font-size: 1.3rem;
    margin-left: 0.5rem;
  }

  .project-info {
    width: 80%;
    padding: 2.5rem 1rem 0 2rem;
  }

  .link-icons {
    top: 2rem;
    right: 1rem;
  }

  .small-project.seu .link-icons {
    top: 2rem;
    left: 1rem;
  }

  .projects-container-text h1 {
    font-size: 2.6rem;
    margin-bottom: 1rem;
  }

  .projects-container-text p {
    font-size: 1.1rem;
  }

  .small-project,
  .large-project {
    height: 26rem;
    width: 100%;
  }

  .sliding-images .row {
    height: 7rem;
  }

  .seu-website-image {
    height: 15rem;
    width: 25rem;
    left: -4rem;
  }

  .gradetracker-image,
  .chore-share-image {
    height: 200px;
  }

  .large-project.java .project-info {
    width: 70%;
  }

  .small-project.seu .project-info h1 {
    width: 60%;
    text-align: end;
  }

  .small-project.seu .project-description {
    width: 80%;
  }

  .projects-container {
    width: 100%;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 0 0 0;
  }
}

@media screen and (max-width: 400px) {
  .project-info {
    width: 80%;
    padding: 2.5rem 1rem 0 2rem;
  }

  .large-project.airline .project-info h1 {
    width: 60%;
  }

  .moving-text-container {
    width: 900%;
    position: absolute;
    bottom: -5%;
    left: -25%;
  }
}

@media screen and (max-width: 380px) {
  .link-icons {
    top: 1rem;
    right: 1rem;
  }

  .small-project.seu .link-icons {
    top: 1rem;
    left: 1rem;
  }

  .projects-container-text h1 {
    font-size: 2.2rem;
    margin-bottom: 1rem;
  }

  .projects-container-text p {
    font-size: 1rem;
  }

  .project-info {
    padding: 1.5rem 1rem 0 2rem;
  }
}

/* Skills Section */

.skills-container {
  width: 100%;
  min-height: 70vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding-top: 6rem;
}

.skills-container-text {
  width: 100%;
  height: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 5rem;
}

.skills-container-text h1 {
  font-size: 3rem;
}

.skills-list-container {
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
}

.skills {
  display: flex;
  flex-direction: row;
  width: 55%;
  flex-wrap: wrap;
  justify-content: center;
}

.skill {
  padding: 1rem 0.7rem;
  min-width: 2rem;
  width: fit-content;
  background: var(--lightgrey);
  border-radius: 0.5rem;
  margin: 0.5rem;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  color: var(--greytext);
}

@media screen and (min-width: 1800px) {
  .skills-container {
    min-height: 50vh;
  }
}

@media screen and (max-width: 940px) {
  .skills-container.section {
    padding: 4rem 0 0 0;
  }

  .skills {
    width: 75%;
  }
}

@media screen and (max-width: 680px) {
  .skills {
    width: 85%;
  }

  .skill {
    min-width: 1.5rem;
    font-size: 1rem;
  }
}

@media screen and (max-width: 440px) {
  .skill {
    min-width: 1.5rem;
    font-size: 0.8rem;
    margin: 0.4rem;
  }

  .skills-container-text h1 {
    font-size: 2.6rem;
  }
}

/* Contact Section */

.contact-container {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.contact-container h1 {
  margin-bottom: 0.3rem;
  font-size: 3rem;
  margin-bottom: 3rem;
}

.contact-container p {
  font-size: 1rem;
}

.contact-container span {
  cursor: pointer;
}

.email {
  margin-bottom: 8rem;
  font-size: 1.3rem !important;
}

.other-links {
  margin-top: 1rem;
}

.other-links .icon {
  font-size: 2rem;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.other-links .icon:hover {
  color: #444444;
}

.rights {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.3rem;
  color: var(--greytext);
  font-size: 0.7rem !important;
}

@media screen and (max-width: 440px) {
  .contact-container h1 {
    font-size: 2.6rem;
  }

  .email {
    font-size: 1.1rem !important;
  }

  .contact-container {
    height: 60vh;
  }
}
